<template>
  <v-card class="pa-4">
    <v-container>
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <h3 class="headtitle">รายละเอียดการแจ้งเบาะแส</h3>
        <h3>{{ data.question }}</h3>
        <v-row>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="data.runningNo"
              label="หมายเลขแจ้งเหตุ"
              disabled
              >{{ data.runningNo }}</v-text-field
            >
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="data.createdAt"
              label="วันที่แจ้งเหตุ"
              disabled
              >{{ data.createdAt }}</v-text-field
            >
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="staffName"
              label="เจ้าหน้าที่ ที่รับเรื่อง"
              disabled
              >{{ staffName }}</v-text-field
            >
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12"> </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-select
              v-model="data.typeWrong"
              label="ลักษณะการทำความผิด"
              :items="[
                'เสพ',
                'ค้า',
                'สมคบ,สนับสนุน',
                'ช่วยเหลือ',
                'ทรัพย์',
                'ชีวิตร่างกายและเพศ',
                'ขับขี่รถด้วยความเร็ว',
                'เสียงดังก่อกวน',
              ]"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.name" label="ชื่อ">{{
              data.name
            }}</v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.nickName" label="ชื่อเล่น/ฉายา">{{
              data.nickName
            }}</v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.gender" label="เพศ">{{
              data.gender
            }}</v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.age" label="อายุ">{{
              data.age
            }}</v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="data.helper"
              label="ผู้ช่วยเหลือ/ผู้สนับสนุน"
              >{{ data.helper }}</v-text-field
            >
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.description" label="รายละเอียด">{{
              data.description
            }}</v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="data.wrongLocation"
              label="สถานที่ใช้กระทำผิด"
              >{{ data.wrongLocation }}</v-text-field
            >
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.vehicle" label="ยานพาหนะ">{{
              data.vehicle
            }}</v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="data.wrongAddress"
              label="ที่อยู่ปัจจุบันของผู้กระทำความผิด"
              >{{ data.wrongAddress }}</v-text-field
            >
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.behavior" label="พฤติการณ์">{{
              data.behavior
            }}</v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-radio-group v-model="data.thisArea">
              เป็นบุคคลในพื้นที่หรือไม่
              <v-radio label="ใช่" value="Yes"></v-radio>
              <v-radio label="ไม่ใช่" value="No"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            ข้อมูลยาเสพติด
            <v-row>
              <v-checkbox v-model="data.drugBah" label="ยาบ้า"></v-checkbox>
              <v-checkbox
                v-model="data.drugHeloine"
                label="เฮโรอีน"
              ></v-checkbox>
              <v-checkbox v-model="data.drugIce" label="ไอซ์"></v-checkbox>
              <v-checkbox v-model="data.drugE" label="ยาอี"></v-checkbox>
              <v-checkbox v-model="data.drugKancha" label="กัญชา"></v-checkbox>
              <v-checkbox v-model="data.drugKatom" label="กระท่อม"></v-checkbox>
              <v-checkbox v-model="data.drugK" label="ยาเค"></v-checkbox>
              <v-checkbox v-model="data.drug" label="อื่นๆ"></v-checkbox>
              <v-checkbox v-model="data.notHave" label="ไม่มี"></v-checkbox>
              <!-- <v-text-field
                    v-if="data.drug == true"
                    label="ยาอื่นๆ โปรดระบุ"
                    v-model="data.drugOther"
                    :rules="rules.required"
                  ></v-text-field> -->
            </v-row>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <span v-if="data.showImage1">ภาพถ่าย</span>
            <v-img :src="data.showImage1"></v-img>
            <!-- <v-text-field
              v-model="data.pic1"
              @click="changePic1()"
              :label="data.imageName1"
              prepend-icon="mdi-camera"
              readonly
            >
            </v-text-field
            ><input
              type="file"
              ref="image"
              id="picTure1"
              accept="image/*"
              @change="showPicture1($event)"
              style="display: none"
          /> -->
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <span v-if="data.showImage2">ภาพถ่าย</span>
            <v-img :src="data.showImage2"></v-img>
            <!-- <v-text-field
              v-model="data.pic2"
              @click="changePic2()"
              :label="data.imageName2"
              prepend-icon="mdi-camera"
              readonly
            >
            </v-text-field
            ><input
              type="file"
              ref="image"
              id="picTure2"
              accept="image/*"
              @change="showPicture2($event)"
              style="display: none"
          /> -->
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.province" label="จังหวัด">{{
              data.province
            }}</v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field v-model="data.station" label="ชื่อสถานี">{{
              data.station
            }}</v-text-field>
          </v-col>
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
          <v-text-field v-model="data.wrongLocation" label="สถานที่กระทำผิด">{{
            data.wrongLocation
          }}</v-text-field>
        </v-col> -->
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
          <v-text-field v-model="data.vehicle" label="ยานพาหนะ">{{
            data.vehicle
          }}</v-text-field>
        </v-col> -->
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
          <v-text-field
            v-model="data.helper"
            label="ผู้ร่วม/ช่วยเหลือ/สนับสนุน"
            >{{ data.helper }}</v-text-field
          >
        </v-col> -->
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
          <v-text-field v-model="data.behavior" label="พฤติการณ์">{{
            data.behavior
          }}</v-text-field>
        </v-col> -->
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-select
              class="mt-2"
              outlined
              dense
              v-model="data.status"
              label="สถานะ"
              :items="data.statusItems"
            >
            </v-select>
          </v-col>
          <v-col
            v-if="
              data.status !== 'ยังไม่ได้ดำเนินการ' &&
              data.status !== 'พบพฤติการณ์' &&
              data.status !== 'ไม่พบพฤติการณ์' &&
              data.status !== 'พิสูจน์ทราบไม่ได้' &&
              data.status !== 'จับกุม' &&
              data.status !== 'รายงานตัว/หยุดพฤิตการณ์' &&
              data.status !== 'หลบหนีออกนอกพื้นที่' &&
              data.status !== 'อยู่ระหว่างสืบสวนติดตาม'
            "
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-text-field v-model="data.status" label="สถานะ" disabled>{{
              data.status
            }}</v-text-field>
          </v-col>
          <v-col
            v-if="data.status != 'ยังไม่ได้ดำเนินการ'"
            cols="12"
            md="6"
            sm="6"
            xs="12"
          >
            <v-textarea
              v-model="data.noteDesc"
              label="บันทึกจากเจ้าหน้าที่"
              :rules="rules.required"
              :rows="3"
              outlined
              >{{ data.noteDesc }}</v-textarea
            >
          </v-col>
        </v-row>
        <v-row>
          <v-btn @click="cancel()">ย้อนกลับ</v-btn>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="submit()">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { Decode, Encode } from "@/services";
export default {
  // DrugClues
  data() {
    return {
      checkValidate: true,
      lazy: false,
      data: {
        runningNo: "",
        id: "",
        // other: "",
        status: "",
        statusItems: [
          { text: "ยังไม่ได้ดำเนินการ", value: "ยังไม่ได้ดำเนินการ" },
          { text: "พบพฤติการณ์", value: "พบพฤติการณ์" },
          { text: "ไม่พบพฤติการณ์", value: "ไม่พบพฤติการณ์" },
          { text: "พิสูจน์ทราบไม่ได้", value: "พิสูจน์ทราบไม่ได้" },
          { text: "จับกุม", value: "จับกุม" },
          { text: "รายงานตัว/หยุดพฤิตการณ์", value: "รายงานตัว/หยุดพฤิตการณ์" },
          { text: "หลบหนีออกนอกพื้นที่", value: "หลบหนีออกนอกพื้นที่" },
          { text: "อยู่ระหว่างสืบสวนติดตาม", value: "อยู่ระหว่างสืบสวนติดตาม" },
          { text: "อื่นๆ", value: "อื่นๆ" },
        ],
        // area: "",
        // address: "",
        // village: "",
        // province: "",
        // question: "",
        // station: "",
        createdAt: "",
        // location: "",
        // vehicle: "",
        // behavior: "",
        // helper: "",
        // address ที่อยู่
        // location สถานที่กระทำผิด
        // vehicle  ยานพาหนะ
        // behavior พฤติการณ์
        // helper  ผู่ช่วยเหลือ/ผู้ร่วม/ผู้สนับสนุน
        //   pic1: null,
        //   pic2: null,
        question: "",
        typeWrong: "",
        name: "",
        nickName: "",
        gender: "",
        age: "",
        helper: "",
        description: "",
        wrongLocation: "",
        vehicle: "",
        wrongAddressChoice: "",
        wrongAddress: "",
        behavior: "",
        thisArea: "",
        // ยาบ้า/เฮโรอีน/ไอซ์/ยาอี/กัญชา/กระท่อม/ยาเค/อื่นๆ ระบุ
        drugBah: false,
        drugHeloine: false,
        drugIce: false,
        drugE: false,
        drugKancha: false,
        drugKatom: false,
        drugK: false,
        notHave: false,
        drugOther: "",
        // name nickName gender age helper description wrongLocation vehicle wrongAddressChoice wrongAddress behavior thisArea drug
        station: this.station,
        village: this.village,
        district: this.district,
        subDistrict: this.subDistrict,
        province: this.province,
        area: this.group,
        imageName1: "ภาพถ่าย(ถ้ามี)",
        imageName2: "ภาพถ่าย(ถ้ามี)",
        pic1: "",
        pic2: "",
        showImage1: "",
        showImage2: "",
        status: "ยังไม่ได้ดำเนินการ",
      },

      staffName: null,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน"],
        password: [(v) => !!v || "Password is required"],
        image: [(v) => !!v || "Image is required"],
        name: [(v) => !!v || "Name is required"],
        sername: [(v) => !!v || "Sername is required"],
        duty: [(v) => !!v || "Duty is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        selectRules: [(val) => !!val || "กรุณากรอกข้อมูล"],
      },
    };
  },
  async created() {
    var DrugClues = JSON.parse(
      Decode.decode(localStorage.getItem("DrugClues"))
    );
    console.log("DrugClues", DrugClues);
    this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
    console.log(this.checklogin);
    this.data.id = DrugClues.id;
    this.data.runningNo = DrugClues.runningNo;
    this.data.question = DrugClues.question;
    this.data.name = DrugClues.name;
    this.data.typeWrong = DrugClues.typeWrong;
    this.data.nickName = DrugClues.nickName;
    this.data.gender = DrugClues.gender;
    this.data.age = DrugClues.age;
    this.data.helper = DrugClues.helper;
    this.data.description = DrugClues.description;
    this.data.wrongLocation = DrugClues.wrongLocation;
    this.data.vehicle = DrugClues.vehicle;
    this.data.wrongAddressChoice = DrugClues.wrongAddressChoice;
    this.data.wrongAddress = DrugClues.wrongAddress;
    this.data.behavior = DrugClues.behavior;
    this.data.thisArea = DrugClues.thisArea;
    this.data.drugBah = DrugClues.drugBah;
    this.data.drugHeloine = DrugClues.drugHeloine;
    this.data.drugIce = DrugClues.drugIce;
    this.data.drugE = DrugClues.drugE;
    this.data.drugKancha = DrugClues.drugKancha;
    this.data.drugKatom = DrugClues.drugKatom;
    this.data.drugK = DrugClues.drugK;
    this.data.notHave = DrugClues.notHave;
    this.data.drugOther = DrugClues.drugOther;
    this.data.area = DrugClues.area;
    this.data.village = DrugClues.village;
    this.data.province = DrugClues.province;
    this.data.station = DrugClues.station;
    this.data.pic1 = DrugClues.pic1;
    this.data.pic2 = DrugClues.pic2;
    this.data.showImage1 = DrugClues.pic1;
    this.data.showImage2 = DrugClues.pic2;
    this.data.status = DrugClues.status;
    this.data.noteDesc = DrugClues.noteDesc;

    var createDate = new Date(DrugClues.createdAt).toLocaleString();
    console.log(createDate);
    this.data.createdAt = createDate;

    // this.data.userId = this.checklogin.id;

    const responseUser = await this.axios.get(
      `${process.env.VUE_APP_API}/user/getOne/` + DrugClues.userId
    );
    if (responseUser.data.response_status == "SUCCESS") {
      console.log(responseUser);
      this.staffName = responseUser.data.data.name;
      console.log(this.staffName);
    } else {
      this.staffName = "-";
    }
    // this.data.createdAt =
    //   createDate.getDate() +
    //   "/" +
    //   (createDate.getMonth() + 1) +
    //   "/" +
    //   createDate.getFullYear() +
    //   ", " +
    //   createDate.getHours() +
    //   ":" +
    //   createDate.getMinutes();

    // console.log(this.data.createdAt);
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.pic1 = reader.result;
          this.data.showImage1 = URL.createObjectURL(element);
          console.log(this.data.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.pic2 = reader.result;
          this.data.showImage2 = URL.createObjectURL(element);
          console.log(this.data.pic2);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    cancel() {
      this.$router.push("ManageDrugClues");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.data.userId = this.checklogin.id;
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/drugclues/update/`,
          this.data
        );
        console.log(response);

        this.$swal.fire({
          icon: "success",
          text: "อัพเดตข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 1000,
        });
        this.$router.push("ManageDrugClues");
      }
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
